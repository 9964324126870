// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

global.$ = require("jquery")
global.Popper = require("popper.js")

// require("jquery")
// require("popper.js")
// import $ from 'jquery';
// import Popper from 'popper.js';

import 'stylesheets/application'

// import 'bootstrap'
import 'bootstrap/js/dist/alert'
import 'bootstrap/js/dist/button'
// import 'bootstrap/js/dist/carousel'
import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/dropdown'
import 'bootstrap/js/dist/index'
import 'bootstrap/js/dist/modal'
import 'bootstrap/js/dist/popover'
// import 'bootstrap/js/dist/scrollspy'
import 'bootstrap/js/dist/tab'
// import 'bootstrap/js/dist/toast'
// import 'bootstrap/js/dist/tooltip'
import 'bootstrap/js/dist/util'

require('javascripts/profile')
require('javascripts/product_service')
require('javascripts/search_widget')


$(document).on("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover({trigger: 'focus'});


  $('button.check-username').on('click', function(){
    var $this = $(this),
      $spinner = $this.find('.spinner'),
      $username = $('.username-field'),
      username = $username.val();
    $this.attr('disabled', true);
    $spinner.removeClass('d-none');
    checkUsername($username, username, function(){
      $this.attr('disabled', false);
      $spinner.addClass('d-none');
    });
    return false;
  });

  $(".with-modal")
    .on('click', function(){
      let $this = $(this),
        $modal = $("#image_modal"),
        $modalImage = $("#modal-image"),
        $modalDialog = $modal.find('.modal-dialog'),
        src = $this.data('full-src') || $this.attr('src') || $this.data('image-src'),
        srcset = $this.data('full-srcset') || $this.attr("srcset") || "",
        modalSize = $this.data('modal-size') || "lg";

      $modalDialog
        .removeClass('modal-sm')
        .removeClass('modal-md')
        .removeClass('modal-lg')
        .removeClass('modal-xl')
        .addClass('modal-' + modalSize);

      let $newImage = $modalImage.clone();
      $newImage
        .attr('src', src)
        .attr('srcset', srcset);
      $modalImage.replaceWith($newImage);

      $modal.modal('show');
    })
    .css('cursor', 'pointer');


  $("#search-widget .close").on('click', function(){
    $("#mobile-search .dropdown-toggle").trigger('click');
    return false;
  })

  $("#q-mobile, #q-widget").on('change', function(){
    let $this = $(this);
    $("#q-mobile, #q-widget").val($this.val());
  });

  // sticky mobile search
  let mobileSearch = document.querySelectorAll("header #mobile-search")[0],
    sticky = null;

  if (mobileSearch !== undefined) {
    sticky = mobileSearch.offsetTop;

    window.onscroll = function(){
      // hacky way to see if item is displayed
      if (mobileSearch.getClientRects()[0] !== undefined) {
        stickySearchBar();
      }
    };
  }

  function stickySearchBar() {
    if (window.pageYOffset > sticky) {
      mobileSearch.classList.add("sticky");
    } else {
      mobileSearch.classList.remove("sticky");
    }
  }


  function doLike(data, callback) {
    $.post('/likes', data, callback, 'json');
  }
  $("button.like").on('click', function() {
    let $this = $(this),
      type = $this.attr('data-type'),
      id = $this.attr('data-id'),
      data = {type: type, id: id},
      $i = $('button.like').find('i'),
      $count = $('button.like').find('.count'),
      disabled = $('button.like').attr('data-disabled');

    if (disabled == 'yes') return false;

    $('button.like').attr('disabled', true);

    $.post('/likes',
      data,
      function(result) {
        $i.each(function(){
          this.outerHTML = result.html;
        });
        $count.text(result.likes);
        $('button.like').attr('disabled', false);
      },
      'json');

  });

});


// form validation stuff copy pasted from Bootstrap docs
(function() {
  'use strict';
  window.addEventListener('load', function() {
    let forms = document.getElementsByClassName('needs-validation');
    let validation = Array.prototype.filter.call(forms, function(form) {
      form.addEventListener('submit', function(event) {

        let usernameFields = document.getElementsByClassName('username-field'),
          usernameDisabled = (usernameFields[0] === undefined) ? false : usernameFields[0].disabled;

        if (usernameFields.length > 0 && !usernameDisabled) {
          event.preventDefault();
          event.stopPropagation();
          
          checkUsername(usernameFields[0], usernameFields[0].value, function(){

            let firstError = document.getElementsByClassName('is-invalid')[0];
            if (firstError !== undefined) {
              firstError.focus();
            }

            form.classList.add('was-validated');
            if (form.checkValidity() === false) {
              event.preventDefault();
              event.stopPropagation();
            } else {
              form.submit();
            }
          });

          return false;
        } else {
          if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
          }
          form.classList.add('was-validated');
          
          let invalidFields = document.querySelectorAll("select:invalid, input.form-control:invalid, textarea.form-control:invalid");
          if (invalidFields.length > 0) {
            invalidFields[0].focus();
          }
        }
      }, false);
    });
  }, false);
})();


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
