function checkUsername(textfield, username, callback){
  let url = "/profile/check/" + username,
    $textfield = $(textfield);
  $.post(url, function(data){
    if (data.available == '1') {
      $textfield[0].setCustomValidity('');
      $textfield.removeClass('is-invalid');
      $textfield.addClass('is-valid');
    } else {
      $textfield[0].setCustomValidity('invalid');
      $textfield.removeClass('is-valid');
      $textfield.addClass('is-invalid');
    }
  }, 'json')
  .always(function(){
    callback();
  });
}

function generateQRCode(targetId, service, address) {
  let typeNumber = 4,
    errorCorrectionLevel = 'L',
    qr = qrcode(typeNumber, errorCorrectionLevel);
  switch(service) {
  case 'cashapp':
    qr.addData('https://cash.app/' + address + '?qr=1');
  case 'bitcoin':
    qr.addData('bitcoin:' + address);
  case 'ethereum':
    qr.addData('ethereum:' + address);
  }
  qr.make();
  document.getElementById(targetId).innerHTML = qr.createImgTag();
}


window.checkUsername = checkUsername;
