import Turbolinks from 'turbolinks';


// to allow turbolinks to work with FORM GETs. Taken from TurboLinks issues page I think
document.addEventListener('turbolinks:load', function(event) {
  for (let form of document.querySelectorAll('form[method=get][data-remote=true]')) {
    form.addEventListener('ajax:beforeSend', function (event) {
      const detail = event.detail,
            xhr = detail[0], options = detail[1];

      Turbolinks.visit(options.url);
      event.preventDefault();
    });
  }
});


$(document).on("turbolinks:load", () => {

  $("#search-filters input, #search-filters select").on('change', function(){
    let $this = $(this),
      name = $this.attr('name'),
      $searchWidget = $("#search-widget"),
      $input = $(`form.search input[name=${name}]`);

    if ($this.attr('type') === 'checkbox') {
      if ($this.prop('checked')) {
        $input.val(1);
      } else {
        $input.val('');
      }
    } else {
      $input.val($this.val());
    }

    $('form.search').attr('action', $("#search-widget form").attr('action'));
    if ($searchWidget.css('overflow-x') == "visible") {   // hack to detect desktop vs mobile
      $("form.search").submit();
    }
  });

  $("#dropdownMenuSortContainer .dropdown-item").on('click', function(){
    let $this = $(this),
      field = $this.attr('data-sort');
    $(`form.search input[name=sort]`).val(field);
    $('form.search').attr('action', $("#search-widget form").attr('action'));
    $("form.search").submit();
  })

  $("#filter-button").on('click', function(){
    let $this = $(this),
      $searchWidget = $('#search-widget');

    $searchWidget.css('display', 'block');

    return false;
  })

  $("#close-search-widget").on('click', function(){
    let $this = $(this),
      $searchWidget = $("#search-widget");
    $searchWidget.css('display', 'none');
    return false;
  });

  $("#mobile-apply-filters").on('click', function(evt){
    let $this = $(this);
    evt.preventDefault();
    $("form.search").submit();
  });

  $("#dropdownTypeContainer .dropdown-item").on('click', function(){
    let $this = $(this),
      $dropdownButton = $("button#dropdownType"),
      field = $this.attr('data-type');
    $(`form.search input[name=type]`).val(field);
    $dropdownButton.text($this.text());
    $dropdownButton.dropdown('hide');
    let $nearGroup = $("#mobile-search .near-group");
    $nearGroup.css('display', 'flex');
    return false;
  });
});


const autoComplete = require("@tarekraafat/autocomplete.js/dist/js/autoComplete");

$(document).on("turbolinks:load", () => {

  // regular search autocomplete
  new autoComplete({
    data: {
      src: async () => {
        const query = document.querySelector(".desktop-search .autoComplete").value;
        let type = document.querySelector(".desktop-search form.search input[name='type']");
        type = (type !== null) ? type.value : '';
        const source = await fetch(`/search/autocomplete?q=${query}&type=${type}`);
        const data = await source.json();
        return data;
      },
      // key: ["tags", "name"],
      cache: false
    },
    selector: '.desktop-search .autoComplete',
    searchEngine: (query, record) => {
      return record;
    },
    highlight: false,
    debounce: 250,
    resultsList: {
      render: true,
      container: source => {
          source.setAttribute("id", "profiles_or_product_suggestions");
      },
      destination: document.querySelector(".desktop-search .autoComplete"),
      position: "afterend",
      element: "ul"
    },
    resultItem: {
      content: (data, source) => {
        source.innerHTML = data.value.name;
      },
      element: "li"
    },
    onSelection: item => {
      const name = item.selection.value.name;
      $(".autoComplete").val(name);
      event.preventDefault();
    }
  });

  // location autocomplete
  new autoComplete({
    data: {
      src: async () => {
        const query = document.querySelector(".desktop-search .autoCompleteLocation").value;
        const type = null;
        const source = await fetch(`/location/autocomplete?q=${query}`);
        const data = await source.json();
        return data;
      },
      // key: ["name"],
      cache: false
    },
    selector: '.desktop-search .autoCompleteLocation',
    searchEngine: (query, record) => {
      return record;
    },
    highlight: false,
    debounce: 250,
    resultsList: {
      render: true,
      container: source => {
        source.setAttribute("id", "location_suggestions");
      },
      destination: document.querySelector(".desktop-search .autoCompleteLocation"),
      position: "afterend",
      element: "ul"
    },
    resultItem: {
      content: (data, source) => {
        source.innerHTML = data.value.name;
      },
      element: "li"
    },
    onSelection: item => {
      const name = item.selection.value.name;
      if (name.indexOf('Current Location') > -1) {
        getCurrentLocation();
      } else {
        $(".autoCompleteLocation").val(name);
        $(".autoCompleteLocationLat").val('');
        $(".autoCompleteLocationLng").val('');
      }
      event.preventDefault();
    }
  });

  // MOBILE
  new autoComplete({
    data: {
      src: async () => {
        const query = document.querySelector("#mobile-search .autoComplete").value;
        let type = document.querySelector("#mobile-search form.search input[name='type']");
        type = (type !== null) ? type.value : '';
        const source = await fetch(`/search/autocomplete?q=${query}&type=${type}`);
        const data = await source.json();
        return data;
      },
      key: ["name", "tags"],
      cache: false
    },
    selector: '#mobile-search .autoComplete',
    searchEngine: "loose",
    highlight: false,
    debounce: 250,
    resultsList: {
      render: true,
      container: source => {
          source.setAttribute("id", "profiles_or_product_suggestions_mobile");
      },
      destination: document.querySelector("#mobile-search #mobile-search-suggestions"),
      position: "afterend",
      element: "ul"
    },
    resultItem: {
      content: (data, source) => {
        source.innerHTML = data.value.name;
      },
      element: "li"
    },
    onSelection: item => {
      const name = item.selection.value.name;
      $(".autoComplete").val(name);
      event.preventDefault();
    }
  });

  new autoComplete({
    data: {
      src: async () => {
        const query = document.querySelector("#mobile-search .autoCompleteLocation").value;
        const type = null;
        const source = await fetch(`/location/autocomplete?q=${query}`);
        const data = await source.json();
        return data;
      },
      key: ["name"],
      cache: false
    },
    selector: '#mobile-search .autoCompleteLocation',
    searchEngine: (query, record) => {
      return record;
    },
    highlight: false,
    debounce: 250,
    resultsList: {
      render: true,
      container: source => {
        source.setAttribute("id", "location_suggestions_mobile");
      },
      destination: document.querySelector("#mobile-search #mobile-search-suggestions"),
      position: "afterend",
      element: "ul"
    },
    resultItem: {
      content: (data, source) => {
        source.innerHTML = data.value.name;
      },
      element: "li"
    },
    onSelection: item => {
      const name = item.selection.value.name;
      if (name.indexOf('Current Location') > -1) {
        getCurrentLocation();
      } else {
        $(".autoCompleteLocation").val(name);
        $(".autoCompleteLocationLat").val('');
        $(".autoCompleteLocationLng").val('');
      }
      event.preventDefault();
    }
  });


  function getCurrentLocation() {
    navigator.geolocation.getCurrentPosition(function(location) {
      let lat = location.coords.latitude,
        lng = location.coords.longitude,
        accuracy = location.coords.accuracy;  // meters
      $(".autoCompleteLocationLat").val(lat);
      $(".autoCompleteLocationLng").val(lng);
      $(".autoCompleteLocation").val('Current Location');
    }, function(error) {
      $(".autoCompleteLocationLat").val('');
      $(".autoCompleteLocationLng").val('');
      $(".autoCompleteLocation").val('');
    });
  }

  $("#mobile-search .autoComplete").on('focus', function(){
    let $this = $(this),
      $nearGroup = $("#mobile-search .near-group");
    $nearGroup.css('display', 'flex');
  });


  const desktopSearchSelectors = {
    profiles_products_suggestions: "#profiles_or_product_suggestions",
    location_suggestions: "#location_suggestions",
    autoComplete: ".desktop-search .autoComplete",
    autoCompleteLocation: ".desktop-search .autoCompleteLocation"
  };

  const mobileSearchSelectors = {
    profiles_products_suggestions: "#profiles_or_product_suggestions_mobile",
    location_suggestions: "#location_suggestions_mobile",
    autoComplete: "#mobile-search .autoComplete",
    autoCompleteLocation: "#mobile-search .autoCompleteLocation"
  };

  ["focus", "blur"].forEach(function(eventType) {

    [desktopSearchSelectors, mobileSearchSelectors].forEach(function(selector) {

      let profiles_products_suggestions = document.querySelector(selector.profiles_products_suggestions),
        location_suggestions = document.querySelector(selector.location_suggestions),
        resultsLists = [profiles_products_suggestions, location_suggestions];

      [selector.autoComplete, selector.autoCompleteLocation].forEach(function(autoCompleter, index) {
        document.querySelector(autoCompleter).addEventListener(eventType, function() {
          if (eventType === "blur") {
            
            resultsLists[index].style.display = "none";

          } else if (eventType === "focus") {
            
            resultsLists[index].style.display = "block";
            
            if (autoCompleter.indexOf(".autoCompleteLocation") > -1) {
                      
              if (location_suggestions.querySelectorAll("li").length == 0) {
                let li = `<li data-id="999" class="autoComplete_result" id='init-current-location'><i class="fas fa-map-marker-alt"></i>&nbsp;&nbsp;Current Location</li>`;
                location_suggestions.innerHTML = li;
                document.querySelector("#init-current-location").addEventListener("mousedown", event => {
                  getCurrentLocation();
                });
              }
            }
          }
        });
      });
    });
  });

});